import React from 'react';
import { Link } from 'gatsby';

import Logo from './Logo';
import Button from '../Button';

const AlternativeHeader = () => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto p-0 px-8">
      <Logo />
      <div className="flex mb-4 sm:mb-0">
        <Link to="/">zur Startseite</Link>
      </div>
      <div className="hidden md:block">
        <a href="https://jim.morrowmed.de">
          <Button className="text-sm">Login</Button>
        </a>
      </div>
    </div>
  </header>
);

export default AlternativeHeader;
