import React from 'react';
import AlternativeLayout from '../components/layout/AlternativeLayout';
import SiteMetadata from '../components/site-metadata';
import SplitSection from '../components/SplitSection';
import Impressum from '../svg/Impressum';

const ImpressumPage = () => (
  <>
    <SiteMetadata pathname="impressum" />
    <AlternativeLayout>
      <SplitSection
        secondarySlot={
          <div className="lg:text-left">
            <h1 className="text-4xl l font-bold leading-none">
              Impressum <br />
            </h1>
            <p className="text-l lg:text-l font-light">
              <br />
              <b>MorrowMed</b> Hapke Ostermann Stetter GbR
              <br />
              <br />
              <b>Geschäftsführung</b> <br />
              Nils Hapke
              <br />
              Basil Ostermann
              <br />
              Dr. med. Maurice Stetter
              <br />
              <br />
              Peterstraße 12 <br />
              97070 Würzburg <br />
              <br />
              kontakt@morrowmed.de <br />
              0173 3893994 <br />
              <br />
              Registereintrag und USt-ID beantragt
            </p>
          </div>
        }
        primarySlot={
          <div className="hidden lg:inline w-full">
            <Impressum />
          </div>
        }
      />
    </AlternativeLayout>
  </>
);

export default ImpressumPage;
